<template>
    <div class="alert">
      <i class="fa fa-warning"></i> Si è verificato un errore nella connessione con il server
    </div> 
</template>

<script>
export default {
  name: "ModalErrorConnection"
};
</script>